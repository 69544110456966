import * as React from 'react';
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import DivPerson from '../components/divPerson';
import DivPersonMobile from '../components/divPersonMobile';
import DivSocial from '../components/divSocial';
import DivSocialMobile from '../components/divSocialMobile';
import DivClique from '../components/divClique';
import DivCliqueMobile from '../components/divCliqueMobile';
import '../sass/app.scss'

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/bg.png"
import bgmobile from "../images/bg-mobile.png"

const IndexPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  return (
    <Layout>
      <Seo title="Paulo Martins" />
      <div className="fullWidth" style={{ backgroundImage: `url(${image})` }}>
        {breakpoints.md || breakpoints.ipad ? (
          <>
            <DivPersonMobile data={data} />

            <DivSocialMobile data={data} />

            <DivCliqueMobile data={data} />

          </>)
          :
          (

            <>
              <DivPerson data={data} />

              <DivSocial data={data} />

              <DivClique data={data} />

            </>
          )}


      </div>


    </Layout>
  )
}

export default IndexPage


export const IndexQuery = graphql`
  query globalJson {
    globalJson {
      info{
        name
        department
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
        imgClick{
          childImageSharp {
            fluid {
              srcWebp
            }
          }
        }
      }
      social{
        alt
        link
        img{
          childImageSharp {
            fluid {
              srcWebp
            }
          }
        }
      }
    }
  }
`
